import {
	Bindings,
	BuildClass,
	Checkbox,
	ConditionalObject,
	FormButton,
	FormButtonSet,
	HelpIcon,
	LoadingSpinnerLarge,
	NewSystemFlyoutInstance,
	Textbox,
	clamp,
	clearServiceWorkerCache,
	moment,
	reactFlyout,
	timer,
	trionlineAjax,
	unsubscribe,
} from '../components'
import { $, React } from '../lib'

const ADUR = 200 // Animation duration

// Global variables
let CACHE_CLEARED = false
const TIMER = null
let checkOnlineStatusCount = 0

const resetPassword = () => {
	// Resetting password - pre-fill the email address
	let url = '/acct/new-password/'
	let email = ($('.txtUser').val() || '') as string
	if (email.indexOf('@') < 0) {
		email = ''
	}
	if (email !== '') {
		url += `?e=${email}`
	}
	location.href = url
}

const RequestAccountFlyout = (props: {
	flyout?: NewSystemFlyoutInstance
}): JSX.Element => (
	<div className="frmRequestAccount">
		<div className="info">
			Please contact your facility administrator to create an account for you.
		</div>
		<FormButtonSet loading={false} msg="" tick={false}>
			<FormButton
				type="standard"
				lbl="Close"
				onClick={() => {
					props.flyout?.Close()
				}}
			/>
		</FormButtonSet>
	</div>
)

const checkOnlineStatus = () => {
	// Pinger to see if the user comes online at some point
	checkOnlineStatusHelper(val => {
		if (!val) {
			// Increment the number of checks
			checkOnlineStatusCount += 1
			// The timer to wait before checking again should slowly increase to a point
			let timeout = 1000 + checkOnlineStatusCount * 500
			timeout = clamp(timeout, 1000, 15000)
			timer(timeout, checkOnlineStatus)
		}
	})
}

var checkOnlineStatusHelper = cb => {
	// Check the online status via an API
	trionlineAjax({
		url: '/api/am-i-signed-in/',
		error: () => cb(false),
		success: data => {
			// Signed in from another tab, refresh to see it
			if (data !== 'NO') {
				location.reload()
				cb(true)
				return
			}

			// Not logged in - clear the cache and stop receiving push notifications
			if (!CACHE_CLEARED) {
				unsubscribe()
				clearServiceWorkerCache()
				CACHE_CLEARED = true
				return
			}

			// Cache has already been cleared, so just wait a bit before responding
			timer(1000, () => {
				cb(false)
			})
		},
	})
}

type loginFormState = {
	// Form values
	userID: string
	pwd: string
	mfa: string
	rememberMe: boolean
	// Form state
	isLoading: boolean
	message: string
	showingMessage: boolean
	showTick: boolean
}
class LoginForm extends React.Component<{}, loginFormState> {
	constructor(props) {
		super(props)
		Bindings(this, [this.signIn])
		this.state = {
			// Form data
			userID: '',
			pwd: '',
			mfa: '',
			rememberMe: false,
			// Form state
			isLoading: false,
			message: '',
			showingMessage: false,
			showTick: false,
		}
	}

	componentDidMount() {
		// Clear the cache and run the status check
		checkOnlineStatus()
	}

	render() {
		return (
			<div className="login-page-wrapper fixed-height-content">
				<div className="login-form-wrapper">
					<div className="box-shadow pnLogin noselect">
						<img
							className="trionline-logo"
							src="/static/img/svg/trionline-christmas.svg"
							alt="TriOnline"
						/>
						{this.buildForm()}
						{this.buildResponse()}
					</div>
				</div>
				{this.buildFooter()}
			</div>
		)
	}

	buildForm() {
		// Username row
		const buildUsername = (
			<label className="input-row">
				{this.buildIcon('ID / Email', '/static/img/svg/login-email.svg')}
				<span className="input-wrapper">
					<Textbox
						id="username"
						className="txtUser"
						value={this.state.userID}
						onUpdate={v => {
							this.setState({ userID: v })
						}}
						type="text"
						placeholder="ID / Email"
					/>
				</span>
				<span className="icon-wrapper help-wrapper">
					<HelpIcon title="You should have received a welcome email to the email address that is linked to your account" />
				</span>
			</label>
		)

		// Password row
		const buildPassword = (
			<label className="input-row">
				{this.buildIcon('Password', '/static/img/svg/login-password.svg')}
				<span className="input-wrapper">
					<Textbox
						value={this.state.pwd}
						onUpdate={v => {
							this.setState({ pwd: v })
						}}
						id="password"
						className="txtPass"
						type="password"
						placeholder="Password"
					/>
				</span>
				<span className="icon-wrapper help-wrapper">
					<HelpIcon title="If you don't know your password or haven't signed in before, click the 'Reset Password' button to create a new one" />
				</span>
			</label>
		)

		// MFA box
		const buildMFA = (
			<label className="mfa">
				{this.buildIcon('MFA', '/static/img/svg/login-mfa.svg')}
				<span className="input-wrapper mfa-wrapper">
					<Textbox
						value={this.state.mfa}
						onUpdate={v => {
							this.setState({ mfa: v })
						}}
						id="mfacode"
						className="txtMFA"
						type="text"
						placeholder="MFA (Optional)"
						maxLength={6}
					/>
				</span>
				<span key="help-wrapper-mfa" className="icon-wrapper help-wrapper">
					<HelpIcon title="Only needed if you have multi-factor security enabled for your account. Generally only needed for users who work with sensitive information" />
				</span>
			</label>
		)

		// Remember me box
		const buildRememberMe = (
			<label className="persistence">
				<span className="remember-me">
					<Checkbox
						value={this.state.rememberMe}
						onUpdate={v => {
							this.setState({ rememberMe: v })
						}}
						lbl=""
					>
						<>
							Remember
							<span className="me"> Me</span>
						</>
					</Checkbox>
				</span>
				<span className="icon-wrapper help-wrapper">
					<HelpIcon title="If checked, you will stay signed in to TriOnline until you explicitly sign out. For security reasons, do NOT use this on shared devices, since others could access your account" />
				</span>
			</label>
		)

		// Sign in button
		const buildSignInButton = (
			<div className="cntr">
				<button className="btnSubmit2 btnSignIn" type="submit">
					Sign In
				</button>
			</div>
		)

		// Request account and reset password links
		const buildRequestAndReset = (
			<div className="cntr request-reset">
				<a
					href="#request"
					onClick={e => {
						e.preventDefault()
						reactFlyout(
							'Request Account',
							[300, 160],
							<RequestAccountFlyout />,
						)
						return false
					}}
				>
					Request Account
				</a>
				<a
					href="#reset"
					onClick={e => {
						e.preventDefault()
						resetPassword()
						return false
					}}
				>
					Reset Password
				</a>
			</div>
		)

		return (
			<form
				className={BuildClass({
					'login-form': true,
					hidden: this.state.showingMessage,
				})}
				onSubmit={e => {
					e.preventDefault()
					this.signIn()
				}}
			>
				{buildUsername}
				{buildPassword}
				<div className="input-row input-row-mfa">
					{buildMFA}
					{buildRememberMe}
				</div>
				<div className="buttons">
					{buildSignInButton}
					{buildRequestAndReset}
				</div>
			</form>
		)
	}

	buildIcon(alt: string, src: string) {
		return (
			<span className="icon-wrapper">
				<img alt={alt} src={src} />
			</span>
		)
	}

	buildResponse() {
		return (
			<div
				className={BuildClass({
					response: true,
					hidden: !this.state.showingMessage,
				})}
			>
				{ConditionalObject(this.state.isLoading, <LoadingSpinnerLarge />)}
				{ConditionalObject(
					this.state.showTick,
					<img
						alt="Success!"
						className="imgTick"
						src="/static/img/svg/tick-large.svg"
					/>,
				)}
				{ConditionalObject(
					Boolean(this.state.message),
					<div className="inline_error_msg">{this.state.message}</div>,
				)}
			</div>
		)
	}

	buildFooter() {
		return (
			<div className="footer-wrapper">
				<div className="box-shadow footer-links">
					<a href="/help/">Help</a>
					<a href="https://trimicro.com.au/" target="_blank" rel="noopener">
						TriMicro
					</a>
					<a href="/privacy/">Privacy</a>
					<a href="/terms/">Terms</a>
					<div className="pnCopyright">
						<span>{`© Software North ${moment().format('YYYY')}`}</span>
						<span>All Rights Reserved</span>
					</div>
				</div>
			</div>
		)
	}

	signIn() {
		// Ensure something has been given
		if (!this.state.userID || !this.state.pwd) {
			return
		}

		// Show loading spinner
		this.setState({
			showingMessage: true,
			isLoading: true,
			message: '',
			showTick: false,
		})

		// Send server request
		trionlineAjax({
			url: '/login/verify.py',
			method: 'POST',
			data: {
				u: this.state.userID,
				p: this.state.pwd,
				mfa: this.state.mfa,
				persistent: this.state.rememberMe,
			},
			no: (msg: string) => {
				timer(() => {
					throw Error(`Login page error: ${JSON.stringify(msg)}`)
				})
				this.flashMessage('Unexpected Error')
			},
			yes: (msg: string) => {
				// Detect if this is an unexpected error
				if (msg.length > 100) {
					console.log(msg)
					this.flashMessage('Unexpected Error')
					return
				}

				// Success tick
				if (msg === 'Successful') {
					this.showSuccecss()
					return
				}

				// Standard error message to display
				this.flashMessage(msg)
			},
		})
	}

	flashMessage(msg: string) {
		this.setState({
			isLoading: false,
			message: msg,
			showingMessage: true,
		})
		timer(ADUR, () => {
			this.setState({
				message: '',
				showingMessage: false,
			})
		})
	}

	showSuccecss() {
		// Put image in the response box
		this.setState({
			isLoading: false,
			message: '',
			showTick: true,
		})

		// Redirect
		timer(ADUR * 2 + 400, () => {
			const path64 = window.rootData?.UrlParts?.args?.rd
			const path = path64 ? atob(path64) : '/'
			location.href = path
		})
	}
}

export const openLoginPage = () => ({
	contentPadding: false,
	theme: 'green',
	transparentBackground: true,
	hideTopNav: true,
	hideSidebar: true,
	content: () => <LoginForm />,
})
